import React from 'react'
import { graphql, Link } from 'gatsby'
// import { Link } from 'gatsby-plugin-modal-routing'
// import Img from 'gatsby-image/withIEPolyfill'
// import { css } from '@emotion/core'
// import classnames from 'classnames'

import Layout from 'templates/layout'
import SEO from 'components/seo'
import Section from 'components/organisms/section'

export default ({
  data: {
    // background,
    allMarkdownRemark: { edges },
  },
}) => (
  <Layout>
    <SEO
      title="スタイリング"
      description="sieniの提案するスタイリングを紹介しています。"
    />
    <div className="t-stylesIndex">
      <Section title="Styling" sub="スタイリング">
        <div className="t-stylesIndex__list">
          {edges &&
            edges.map(({ node: style }) => (
              <Link className="t-stylesIndex__thumbnail" to={style.fields.slug}>
                <img
                  src={
                    style.frontmatter.thumbnail_url ||
                    style.frontmatter.photo.publicURL
                  }
                  alt=""
                />
              </Link>
            ))}
        </div>
      </Section>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "styles" }, status: { eq: true } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            photo {
              publicURL
            }
          }
        }
      }
    }
  }
`
